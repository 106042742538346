import { Route, Routes } from "react-router-dom";
import Layout from "./Pages/Layout";
import Login from "./Pages/Login";
import Home from './Pages/Home';
import CompanyOverview from "./Pages/CompanyOverview";
import TheMission from "./Pages/TheMission";
import MultipleRevenueStreams from "./Pages/MultipleRevenueStreams";
import Books from "./Pages/Books";
import FilmPlataform from "./Pages/FilmPlataform";
import Broadway2023 from "./Pages/Broadway2023";
import NovelsScripts from "./Pages/NovelsScripts";
import InDevelopment from "./Pages/InDevelopment";
import TelevisionMediaStreaming from "./Pages/TelevisionMediaStreaming";
import UseOfFunds from "./Pages/UseOfFunds";
import InvestmentAmendment from "./Pages/InvestmentAmendment";
import SuzanneCorso from "./Pages/SuzanneCorso";
import Press from "./Pages/Press";
import 'bootstrap/dist/css/bootstrap.css';
import './App.scss';

const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/Authenticate" element={<Login />} />
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/CompanyOverview" element={<CompanyOverview />} />
          <Route path="/TheMission" element={<TheMission />} />
          <Route path="/MultipleRevenueStreams" element={<MultipleRevenueStreams />} />
          <Route path="/Books" element={<Books />} />
          <Route path="/FilmPlataform" element={<FilmPlataform />} />
          <Route path="/Broadway2024" element={<Broadway2023 />} />
          <Route path="/NovelsScripts" element={<NovelsScripts />} />
          <Route path="/InDevelopment" element={<InDevelopment />} />
          <Route path="/TelevisionMediaStreaming" element={<TelevisionMediaStreaming />} />
          <Route path="/UseOfFunds" element={<UseOfFunds />} />
          <Route path="/InvestmentAmendment" element={<InvestmentAmendment />} />
          <Route path="/SuzanneCorso" element={<SuzanneCorso />} />
          <Route path="/Press" element={<Press />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
