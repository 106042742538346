import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const TelevisionMediaStreaming = () => {

  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('auth') !== '1') {
      navigate("/Home");
    }
  }, []);

  return (
    <div className='streaming-platform'>
      <div className='title'>
        Television/Media
      </div>
      <div className='streaming-title'>
        Streaming Platform
      </div>
      <div className='main-container'>
        <div className='box-section mt-3 ms-3 me-3'>
          <div className='box1 me-3'>
            <div className='media-section'>
              <div className='media-title'>
                SAMCOR PRODUCTIONS CREATED & WRITTEN
              </div>
            </div>
            <div className='media-description'>
              by Suzanne Corso "I SLEPT WITH A CRIMINAL"- REALITY Series for TV
            </div>
            <div className='media-divider'></div>
            <div className='description'>
              Why do the good girls always want the bad boys? Hybristophilia- A paraphilia of the predatory type in which sexual arousal, facilitation, and attainment of orgasm are responsive to and contingent upon being with a partner known to have committed a crime. n popular culture, this phenomenon is also known as "Bonnie and Clyde Syndrome." Some women may dream of their knight in shining armor, but just as many dreams of a dark prince. A recently released study from the University of Durham found that women are attracted to men who possess what they call the "dark triad" of personality traits - dominance, narcissism, and Machiavellianism - the same traits that often defne a criminal. From Jeremy Meeks, the gangbanger whose mug shot exploded across the internet last summer with the hashtag #sexycriminal, to the impending marriage of Charles Manson, it's clear some women lust after criminals, and the rest of us, well we just love to hear the stories.
            </div>
          </div>
          <div className='box1 me-3'>
            <div className='media-section'>
              <div className='media-title'>
                SAMCOR PRODUCTIONS CREATED & WRITTEN
              </div>
            </div>
            <div className='media-description'>
              by Suzanne Corso "CRITICS & CASH"- REALITY Series for TV Hosted by Scott Mantz & Suzanne Corso
            </div>
            <div className='media-divider'></div>
            <div className='description'>
              Hosted by Scott Mantz & Suzanne Corso
              Scott Mantz- Website www.MovieMantz.com Emmy- nominated Mantz has been reviewing movies for 15 years. Winner of the 2014 CG Publicists Guild Press Award (honoring outstanding entertainment journalism), Scott "Movie" Mantz is the chief Film Critic and Film Segment Producer for "Access Hollywood" An inside and candid look at the world of movies. The Reviews, the making of them, the money raised, the producers and the fnanciers. What is takes to really get it done. The highs and lows. The efects on corporate and everyday business that surround it. Wall Street and Main Street care about the entertainment industry. And of course, social media and all marketing aspects. This show is the one stop show to fnd it all out. We will explore fnancial strategies from the page, to fnancing, to screen. To the ultimate thing we all want ...The CRITIQUE!
            </div>
          </div>
        </div>
        <div className='box-section mt-3 ms-3 me-3'>
          <div className='box1 me-3'>
            <div className='media-section'>
              <div className='media-title'>
                SAMCOR PRODUCTIONS CREATED & WRITTEN
              </div>
            </div>
            <div className='media-description'>
              by Suzanne Corso "RICH TO REAL"- REALITY Series for TV
            </div>
            <div className='media-divider'></div>
            <div className='description'>
              Rich to Real is what was bestowed upon me. lived within three large entities, all on which my books are based upon. Mafa, Wall Street and Hollywood, however, when you add Politics and Religion you create a formula for women that can be able to fnally divulge what they have been wanting to discuss for years, but where too silent to do so, until now. t's time for women to speak out about how the men in their lives have taken them from riches to realness with a few sprints around a block. Life can change in an instance. At the end of the day always say, "Money doesn't defne you, how you react to losing it does". That's why talk to hundreds of women about creating your own identity and moving on. Within this show you can do so by speaking out about how life's sudden bumps turn into a realization they never saw coming. will ask the right questions This unlike any other conversation. The 5 categories of power; HOLLYWOOD, WALL STREET, RELIGION, POLITICS & MAFIA.
            </div>
          </div>
          <div className='box1 me-3'>
            <div className='media-section'>
              <div className='media-title'>
                SAMCOR PRODUCTIONS CREATED & WRITTEN
              </div>
            </div>
            <div className='media-description'>
              by Suzanne Corso "WHAT IF"- REALITY Series for TV
            </div>
            <div className='media-divider'></div>
            <div className='description'>
              Is based upon "Butterfly Efect". A subtle change 20 years ago could have a Major efect on today's business, economic & world Through this show we will see where no one has gone before. Research: Click here: Butterfly efect - Wikipedia, the free encyclopedia Will take you back in time to what if this never happened. What would be the outcome, the turn of events and how exactly these events would afect our economic windfall. The Timeline in American History is what feeds our episodes. All episodes lead up to the fnancial gains or losses in our society today to the ultimate WHAT F.. this had not occurred where would we be today in history. We would show what may have happened if certain circumstances were changed. Somewhat like a; Back in Time. Revisiting scandals/ disasters/ triumphs/memorable events.... From the eyes and voices of the People who reported on it then.
            </div>
          </div>
        </div>
        <div className='box-section mt-3 ms-3 me-3'>
          <div className='box1 me-3'>
            <div className='media-section'>
              <div className='media-title'>
                SAMCOR PRODUCTIONS CREATED & WRITTEN
              </div>
            </div>
            <div className='media-description'>
              by Suzanne Corso "SEX SECRETS OF THE SUPER RICH"- REALITY Series for TV
            </div>
            <div className='media-divider'></div>
            <div className='description'>
              Sexy female intro's and outro's each episode while seated in business suit with fishnets and heels. An inside and candid look at the world of sex from the POV of the mega rich. Sexual escapades are never out of reach or prejudice or discriminate. Episodes: Wall Street, Business, Fashion, Mafa, Hollywood, Politics, Sports, Music, Rock n Roll
            </div>
          </div>
          <div className='box1 me-3'>
            <div className='media-section'>
              <div className='media-title'>
                SAMCOR PRODUCTIONS CREATED & WRITTEN
              </div>
            </div>
            <div className='media-description'>
              by Suzanne Corso "CRITICS & CASH"- REALITY Series for TV Hosted by Scott Mantz & Suzanne Corso
            </div>
            <div className='media-divider'></div>
            <div className='description'>
              6 part or 12-part series. Click here: 12 Historical Women Who Gave No F*cks
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TelevisionMediaStreaming;
