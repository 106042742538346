import React, { useEffect, useState,useRef } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { NavDropdown, NavItem } from 'react-bootstrap';
import { FaLocationDot } from 'react-icons/fa6';
import { MdMail } from 'react-icons/md';
import { BiSolidPhone } from 'react-icons/bi';
import Logo from "../Image/logo.png";
import RightArrow from "../Image/right.png";
import Menu from "../Image/Menu_Bar.png";

const Header = () => {
    const ref = useRef<HTMLHeadingElement>(null);
    const [isOpen, setOpen] = useState(false);
    const location = useLocation();
    const gotoSection = (section: string) => {
        const element = document.getElementById(section);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const checkIfClickedOutside = (e:any) => {
            if (isOpen && ref.current && !ref.current.contains(e.target)) {
                setOpen(false)
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [isOpen])

    return (
        <div className='header'>
            <div className="navbar-section">
                <Link to="/Home">
                    <img src={Logo} className='header-logo' />
                </Link>
                {(location.pathname === '/' || location.pathname === '/Home') &&
                    <nav className="nav nav-web">
                        <a onClick={() => { gotoSection('home') }}>Home</a>
                        <a onClick={() => { gotoSection('about') }}>About</a>
                        <a onClick={() => { gotoSection('book') }}>My Books</a>
                        {/* <a>Samcor Production</a> */}
                        {/* <a>Contact Us</a> */}
                    </nav>}
                {(location.pathname === '/' || location.pathname === '/Home') &&
                    <Link to="/Authenticate">
                        <button className='btn btn-primary login-btn'>
                            <img src={RightArrow} width={25} className='me-1' />Login
                        </button>
                    </Link>}
                {(location.pathname !== '/' && location.pathname !== '/Home') &&
                    <div className='contact-header-section'>
                        <div className='text'><FaLocationDot color={'#ED1C24'} size={20} className="me-2" />71 Broadway, Suite 18E NYC, NY 10006</div>
                        <div className='text'><a href="mailto:SueCorso88@gmail.com"><MdMail color={'#ED1C24'} size={20} className="me-2" />SueCorso88@gmail.com</a></div>
                        <div className='text'><a href="tel:+19178873372"><BiSolidPhone color={'#ED1C24'} size={20} className="me-2" />(917) 887-3372</a></div>
                    </div>
                }
                {(location.pathname !== '/' && location.pathname !== '/Home') &&
                    <NavDropdown ref={ref}  show={isOpen} onClick={()=>{setOpen(!isOpen)}} title={<img src={Menu} width={47} height={47} />}
                        id="basic-nav-dropdown">
                        <Link to="/CompanyOverview">
                            <NavItem className={`custom-nav-item ${location.pathname === '/CompanyOverview' && 'active'}`}>
                                Company
                            </NavItem>
                        </Link>
                        <Link to="/TheMission">
                            <NavItem className={`custom-nav-item ${location.pathname === '/TheMission' && 'active'}`} >The Mission</NavItem>
                        </Link>
                        <Link to="/MultipleRevenueStreams">
                            <NavItem className={`custom-nav-item ${location.pathname === '/MultipleRevenueStreams' && 'active'}`} >Multiple Revenue Streams</NavItem>
                        </Link>
                        <Link to="/Books">
                            <NavItem className={`custom-nav-item ${location.pathname === '/Books' && 'active'}`} >Books</NavItem>
                        </Link>
                        <Link to="/FilmPlataform">
                            <NavItem className={`custom-nav-item ${location.pathname === '/FilmPlataform' && 'active'}`} >Film Platform</NavItem>
                        </Link>
                        <Link to="/Broadway2024">
                            <NavItem className={`custom-nav-item ${location.pathname === '/Broadway2024' && 'active'}`} >Broadway 2024</NavItem>
                        </Link>
                        <Link to="/NovelsScripts">
                            <NavItem className={`custom-nav-item ${location.pathname === '/NovelsScripts' && 'active'}`} >Novels & Scripts</NavItem>
                        </Link>
                        <Link to="/InDevelopment">
                            <NavItem className={`custom-nav-item ${location.pathname === '/InDevelopment' && 'active'}`} >In Development</NavItem>
                        </Link>
                        <Link to="/TelevisionMediaStreaming">
                            <NavItem className={`custom-nav-item ${location.pathname === '/TelevisionMediaStreaming' && 'active'}`} >Television/Media Streaming</NavItem>
                        </Link>
                        <Link to="/UseOfFunds">
                            <NavItem className={`custom-nav-item ${location.pathname === '/UseOfFunds' && 'active'}`} >Use of Funds</NavItem>
                        </Link>
                        <Link to="/InvestmentAmendment">
                            <NavItem className={`custom-nav-item ${location.pathname === '/InvestmentAmendment' && 'active'}`} >Investment Amendment</NavItem>
                        </Link>
                        <Link to="/SuzanneCorso">
                            <NavItem className={`custom-nav-item ${location.pathname === '/SuzanneCorso' && 'active'}`} >Suzanne Corso </NavItem>
                        </Link>
                        <Link to="/Press">
                            <NavItem className={`custom-nav-item ${location.pathname === '/Press' && 'active'}`} >Press</NavItem>
                        </Link>
                    </NavDropdown>
                }
            </div>
            {(location.pathname === '/' || location.pathname === '/Home') &&
                <div className='nav-mobile'>
                    <nav className="nav">
                        <a onClick={() => { gotoSection('home') }}>Home</a>
                        <a onClick={() => { gotoSection('about') }}>About</a>
                        <a onClick={() => { gotoSection('book') }}>My Books</a>
                        <a>Samcor Production</a>
                        <a>Contact Us</a>
                    </nav></div>}
        </div>
    )
}

export default Header