import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from "../Image/logo.png";

const CompanyOverview = () => {
    const navigate =  useNavigate();
    useEffect(()=>{
       if(localStorage.getItem('auth') !== '1'){
        navigate("/Home");
       }
    },[]);
    return (
        <div className='company-overview-section'>
            <div className='main-container'>
                <div className='row mb-5'>
                    <div className='col-md-7'>
                        <div>
                            <div className='company-title'>
                                Company <span style={{ color: "#ED1C24" }}>Overview</span>
                            </div>
                            <div className='company-label mt-3 mb-3'>
                                <span>SAMCOR Productions, Inc.</span> is a production company with projects in Film, Television & Broadway. Our goal is empowering female driven works, that can impact all.
                            </div>
                            <div className='overview-section'>
                                <div className='red-line'>
                                    <div className='write-section'>
                                        <span>Film;</span> Brooklyn Story; The Suite Life & Hello Hollywood; Trilogy. Rough Cuts; the diamond Trilogy.
                                    </div>
                                    <div className='write-section mt-3'>
                                        <span>TV Series & Media Streaming;</span> Grayrocking & M A R Y. Sammy & Sue; mother and daughter explorer team to save the earth. A children’s venture based on the bestselling books.
                                    </div>
                                    <div className='write-section mt-3'>
                                        Also, six reality shows, which are attached to this
                                        document. SAMCOR Productions, Inc. also has a presence
                                        on the internet. It seeks a clean and informative design that
                                        will showcase its projects. When potential investors, writers,
                                        producers, directors, etc., ask for information about the
                                        company they will be able to be directed to the web site for an
                                        overview of the company.
                                    </div>
                                    <div className='write-section mt-3'>
                                        <span>
                                            (This site includes sections on current projects, projects in
                                            development, information about the company and its principal).
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-5'>
                        <div className="image-section">
                                <div>
                                    <img src={Logo} />
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompanyOverview;