import React, { useEffect } from 'react';
import Media from "../Image/Media.png"
import { useNavigate } from 'react-router-dom';

const FilmPlataform = () => {
    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('auth') !== '1') {
            navigate("/Home");
        }
    }, []);
    return (
        <div className='film-platform'>
            <div className='title'>
                Project/Based on Books
            </div>
            <div className='film-title'>
                Film Platform
            </div>
            <div className='main-container'>
                <div className='box-section mt-3 ms-3 me-3'>
                    <div className='box1 me-3'>
                        <div className='media-section'>
                            <div>
                                <img className='me-2' src={Media} height={35} width={35} />
                            </div>
                            <div className='media-title'>
                                SAMCOR <br /> PRODUCTIONS
                            </div>
                        </div>
                        <div className='media-description'>
                            by Suzanne Corso, based on the bestselling novel “Brooklyn Story” (Simon & Schuster)
                        </div>
                        <div className='media-divider'></div>
                        <div className='description'>
                            <span>"BROOKLYN STORY" </span>A classic journey of a young woman's struggle to forge ahead in order to gain her own identity. While faced with challenges of leaving behind her dangerous boyfriend and a bleak Brooklyn life in the 1970's. SAMANTHA BONT grew up with her doting Jewish grandmother and sickly, neurotic, converted Catholic mother. She skillfully walks through the verbal minefeld that is their small lower class apartment in Brooklyn. Samantha is a paradox: sweet and intense, innocent and worldly. While her mother and grandmother have tried to protect her from no-good men like her dead beat father, no one can prevent her from getting older and venturing out into the perilous world that is Brooklyn in the late 1970's.
                        </div>
                    </div>
                    <div className='box1 bg-color me-3'>
                        <div className='media-section'>
                            <div>
                                <img className='me-2' src={Media} height={35} width={35} />
                            </div>
                            <div className='media-title text-white'>
                                SAMCOR <br /> PRODUCTIONS
                            </div>
                        </div>
                        <div className='media-description'>
                            by Suzanne Corso, based on the novel “The Suite Life” (Simon & Schuster)
                        </div>
                        <div className='media-divider'></div>
                        <div className='description text-white'>
                            <span>"THE SUITE LIFE" </span>The follow-up to Suzanne Corso's debut novel, A Brooklyn Story, chronicles Samantha Bonti's life as a young woman from Brooklyn struggling to rise above her poverty-stricken background and fnd love and success "over the bridge" in Manhattan. When she meets the man of her dreams in Alec, a successful Wall Street broker, she thinks her prayers for stability and security have been answered. But as she struggles to carve out a place for herself in Alec's world-the excessively decadent, secretly seedy, and wholly unfamiliar world of Manhattan's elite-she begins to wonder if she'll ever truly feel at home by Alec's side. And when a risky business move coincides with a market crash, Alec's fortune begins to slip away, and Samantha is left scrambling to pick up the pieces of her swiftly crumbling fairy tale.
                        </div>
                    </div>
                    <div className='box1 me-3'>
                        <div className='media-section'>
                            <div>
                                <img className='me-2' src={Media} height={35} width={35} />
                            </div>
                            <div className='media-title'>
                                SAMCOR <br /> PRODUCTIONS
                            </div>
                        </div>
                        <div className='media-description'>
                            by Suzanne Corso, based on the novel “Hello Hollywood”(Simon & Schuster)
                        </div>
                        <div className='media-divider'></div>
                        <div className='description'>
                            <span>“HELLO HOLLYWOOD” </span>Suzanne Corso, selected by USA Today as a "New Voice of 2011" for her powerful novel Brooklyn Story, concludes her whirlwind Cinderella trilogy featuring a poor girl with big dreams and a writing talent to match. Swept of her feet and into a life of luxury by a handsome Wall Street banker, Samantha Bonti believes she has fnally left behind her mobster- run Brooklyn neighborhood. Then her husband proves to be a man who earns big, but spends even more, and who takes his frustrations out on Sam. His sudden death from a heart attack, however, leaves her a new hand to play-courtesy of his ffteen million dollar life insurance policy-especially when her novel based on her girlhood becomes a bestseller. Finally independent, Sam moves with her daughter to Hollywood, where she can participate in the production of the movie version of her novel.
                            Dating the flm's producer, however, reveals a dark side to all the Hollywood glam, especially when fgures from her Brooklyn past start showing up where they are defnitely not wanted. And then there's a mysterious man named John who is showering her with attention. s this a new romance, or another misstep? And is Sam's turn in the spotlight worth all the real life drama?
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FilmPlataform;
