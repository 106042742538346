import React, { useEffect } from 'react'
import TransactionChart from "../Image/Chart.png"
import { useNavigate } from 'react-router-dom';

export default function UseOfFunds() {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('auth') !== '1') {
      navigate("/Home");
    }
  }, []);
  return (
    <div className='use-of-fund-section'>
      <div className='title'>The Transaction</div>
      <div className='use-of-fund-title'>Use of Funds</div>
      <div className='use-of-fund-table-section'>
        <div className='left-box'>
          <div>Funding target $ 1,500,000</div>
          <div>Structure Class A Preferred</div>
          <div>Timing 2023</div>
        </div>
        <div className='right-box'>
          <div>Use of Proceeds:</div>
          <div>Primary Cash Expenditures.</div>
          <div>Capitalize Samcor.</div>
        </div>
      </div>
      <div className='img-section'>
        <img src={TransactionChart} alt='image' className='img-fluid' />
      </div>
    </div>
  )
}
