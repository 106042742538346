import React, { useEffect } from 'react'
import Press11 from './../Image/Press_11.png';
import New_Yourk from './../Image/New_Yourk.png';
import Usa_Today from './../Image/Usa_Today.png';
import News from './../Image/News.png';
import First_Press from './../Image/First_Press.png';
import Second_Press from './../Image/Second_Press.png';
import Third_Press from './../Image/Third_Press.png';
import { useNavigate } from 'react-router-dom';


export default function Press() {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('auth') !== '1') {
      navigate("/Home");
    }
  }, []);
  return (
    <>
      <div className='press'>
        <div className='left-side'>
          <img src={Press11} alt="img" className='img-fluid' />
        </div>
        <div className='right-side'>
          <div className='detail-section'>
            <div className='title red-color'>Press</div>
            <div className='title'>Suzanne <span className='red-color'>Corso</span></div>
            <div className='paragraph'>
              <p>I met the love of my life in early 1996, just a few years after I'd finally broken free from a tumultuous relationship with a man involved in organized crime. When my future husband and I noticed each other on a Manhattan street, the spark seemed instant: He was warm, engaging, and courteous—the exact opposite of the controlling abuser I'd endured. He was also a Wall Street hotshot whose wealth seemed worlds away from the poverty I'd experienced as a child in Brooklyn. We married, had a daughter, and settled into a financially comfortable life. As I took my place in this new world, I quickly began dressing the part in Hermès scarves, Versace dresses, and Prada heels.But when the stock market crashed in 2008, our lifestyle changed dramatically. We went from living as multimillionaires with an 11-room minimansion to surviving paycheck to paycheck in a two-bedroom apartment. I won't lie and say it didn't bother me when I'd walk down Madison Avenue and see all the things I couldn't buy.</p>
            </div>
          </div>
        </div>
      </div>
      <div className='press-section'>
        <div className='paragraph'>
          <p>At the time, I would've told you I was devastated. Then I started to think about my situation: People all over the world live in desperate conditions. Was losing millions really that devastating? After all, my relationship with my husband was still strong. I finally told myself, "Get over it."Yes, we lost all our money, yet I gained a strong sense of who I am—a woman who will never again be controlled by another person or defined by material possessions. These days I can wander out in a T-shirt and jeans and feel far more beautiful than I did two decades ago.</p>
        </div>
        <div className='title'>
          More <span>Post</span>
        </div>
        <div className='box-section'>
          <div className='box1 me-3'>
            <div className='img-section'>
              <img src={New_Yourk} className='img-fluid' />
            </div>
            <div className='new-section'>
              <img src={Third_Press} className='img-fluid' />
            </div>
          </div>
          <div className='box1 me-3'>
            <div className='img-section1'>
              <img src={Usa_Today} className='img-fluid' />
            </div>
            <div className='new-section'>
              <img src={Second_Press} className='img-fluid' />
            </div>
          </div>
          <div className='box1'>
            <div className='img-section'>
              <img src={News} className='img-fluid' />
            </div>
            <div className='new-section'>
              <img src={First_Press} className='img-fluid' />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
