import Logo from "../Image/logo.png"
import RedArrow from "../Image/redArrow.png"
import WhiteRightArrow from "../Image/White_Right_Arrow.png"
import Book1 from "../Image/book 1.png"
import Book2 from "../Image/book 2.png"
import Book3 from "../Image/book 3.png"
import Person from "../Image/person.png"
import { useEffect } from "react";
import { useNavigate } from "react-router-dom"

const Home = () => {
    const navigate = useNavigate();

    const gotoSection = (section: string) => {
        const element = document.getElementById(section);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        localStorage.setItem('auth', '0');
    }, []);
    

    return (
        <div className="home-section">
            <div className='overview-section'>
                <div className='main-container'>
                    <div className='row'>
                        <div className='col-md-7 pt-4 pb-3'>
                            <div className='title'>
                                SAMCOR Productions, Inc.
                            </div>
                            <div className='label'>
                                Is a production company with projects in Film,<br /> Television & Broadway.
                            </div>
                            <div className='paragraph'>
                                Our goal is empowering female driven works, that can impact all. Film; Brooklyn Story; The Suite Life & Hello Hollywood; Trilogy. Rough Cuts; the diamond Trilogy. TV Series & Media Streaming; Grayrocking & M A R Y. Sammy & Sue; mother and daughter explorer team to save the earth. A children’s venture based on the bestselling books. Also, six reality shows, which are attached to this document. SAMCOR Productions, Inc. also has a presence on the internet. It seeks a clean and informative design that will showcase its projects. When potential investors, writers, producers, directors, etc., ask for information about the company they will be able to be directed to the web site for an overview of the company. (This site includes sections on current projects, projects in development, information about the company and its principal).
                            </div>
                            <div className='paragraph mt-2'>
                                SAMCOR Productions, Inc. Will combine its content and expertise to partner with the right companies in film, television and media streaming. To produce the highest quality of entertainment viewing with purpose. With a solid mission in mind for women’s programming, as well as, high-quality commercial programming.
                            </div>
                            <div className='row mt-4 mb-3'>
                                <div className='col-sm-12 d-flex button-section'>
                                    <button className='btn redirect-btn' onClick={()=>{gotoSection('book');}}>
                                        My Books
                                        <img src={WhiteRightArrow} height={25} width={25} />
                                    </button>
                                    <button className='btn redirect-btn bg-color-black' onClick={()=>{navigate("/Authenticate")}}>
                                        Samcor Production
                                        <img src={RedArrow} height={25} width={25} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-5'>
                            <div className="image-section">
                                <img src={Logo} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='profile-section' id="about">
                <div className='main-container'>
                    <div className='row'>
                        <div className='col-md-6 col-sm-12'>
                            <img src={Person} height={500} width={550} className="img-fluid" />
                        </div>
                        <div className='col-md-6 col-sm-12'>
                            <div className='title'>
                                About <span style={{ color: "red" }}>Us</span>
                            </div>
                            <div className='person-name'>
                                Suzanne Corso
                            </div>
                            <div className='label'>
                                Auhor/Screenwriter/Producter
                            </div>
                            <div className='paragraph'>
                                Corso is the author of three novels. Brooklyn Story (2011), The Suite Life (2013) & Hello Hollywood (2015), the last part of the trilogy; (Simon& Schuster). She is also completing her highly waited trilogy; Rough Cuts, set in exotic locals encompassing the very alluring diamond industry; to be published 2024. She has also penned the screenplay to Brooklyn Story. Corso has produced for the New York and London stages. She has also produced two documentaries and has written a children's book. Corso is currently in development of The Suite Life & Hello Hollywood to TV Series. She was a contributing TV Film Correspondent to NBC Today Show and columnist for Downtown Magazine. Currently Brooklyn Story is being negotiated into a feature flm.
                            </div>
                            {/* <div className="read-more-btn-section">
                                <button className='btn read-more-btn mt-4 mb-3'>
                                    Read More
                                    <img src={WhiteRightArrow} height={25} width={25} />
                                </button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className='book-section' id="book">
                <div className='title'>
                    My <span style={{ color: "red" }}>Books</span>
                </div>
                <div className='main-container'>
                    <div className='box-section'>
                        <div className='red-box'>
                            <div style={{ position: "relative" }}>
                                <div className="image-section">
                                    <img src={Book1} className="img-fluid" />
                                </div>
                            </div>
                            <div className='book-title'>
                                BROOKLYN<br />STORY
                            </div>
                            <div className='paragraph'>
                                In 1982 at age fifteen, through my faith and strength I managed to escape the days as a child consumed by a dangerous relationship with an abusive criminal. As well as, being the product of a Jewish mother and a Sicilian-Catholic father, who abandoned me at birth and beat my mother senseless. It made me a half-breed and hesitantly edging toward pure Brooklyn. My eccentric mother Joan was a cynic shackled with addictions and made me attend church, all the time unaware of my Jewish roots. My Jewish Grandma Ruth had become my most opinionated source of encouragement. These traditions bestowed upon me became terrifying roadblocks and hindered the independent woman I so desperately wanted to become. My empowering journey of leaving the past to history and the future to fate. As I made my move to restore hope where there was none and reach for my dream of a new life as a writer in an inspiring promise of paradise called Manhattan. The lights of the Brooklyn Bridge, my entre to freedom, my way out.
                            </div>
                            {/* <div className="action-section">
                                <button className='btn more-btn bg-color-white color-white'>
                                    Learn More
                                    <img className='ms-1' src={RedArrow} height={25} width={25} />
                                </button>
                            </div> */}
                        </div>
                        <div className='white-box'>
                            <div style={{ position: "relative" }}>
                                <div className="image-section">
                                    <img src={Book2} className="img-fluid" />
                                </div>
                            </div>
                            <div className='book-title color-black'>
                                THE SUITE<br />LIFE
                            </div>
                            <div className='paragraph color-black'>
                                Became the follow-up, chronicles Samantha’s life as a young woman from Brooklyn struggling to rise above her poverty-stricken background and find love and success “over the bridge” in Manhattan. . Along the way, Samantha struggles with depression, confused by her upbringing of Jewish/Catholic religious dichotomy from her drug addicted mother and forceful grandmother’s hands and must then carry with her those challenges once she leaves. When she meets the man of her dreams in Alec, a successful Wall Street broker. She thinks her prayers for stability and security have been answered. However, she struggles to carve out a place for herself in Alec’s world—the excessively decadent, secretly seedy, and unfamiliar territory of Manhattan’s elite. She begins to wonder if she will ever truly feel at home by Alec’s side. And when a risky business move coincides with a market crash, Alec’s fortune begins to slip away, and Samantha is left scrambling to pick up the pieces of her swiftly crumbling fairy tale. Samantha, being the woman of faith, she is, manages to make it out once again to the high stakes’ world of Hollywood. Obtaining a major book and movie deal that only Hollywood can give her.
                            </div>
                            {/* <div className="action-section">
                                <button className='btn more-btn color-white'>
                                    Learn More
                                    <img className='ms-1' src={WhiteRightArrow} height={25} width={25} />
                                </button>
                            </div> */}
                        </div>
                        <div className='red-box'>
                            <div style={{ position: "relative" }}>
                                <div className="image-section">
                                    <img src={Book3} className="img-fluid" />
                                </div>
                            </div>
                            <div className='book-title'>
                                HELLO,<br />HOLLYWOOD
                            </div>
                            <div className='paragraph'>
                                The culmination of her life and transformation is and will always be The Brooklyn Bridge. Samantha’s symbol throughout which shows her life emerging into Wall Street and eventually to Hollywood. She depends on those strong cables that the bridge has wrapped around her always keeping her grounded, giving her the strength, she needs to survive these powerful worlds. Again Samantha, is swept into a chaotic mess. Alec’s sudden death from a heart attack leaves her a new hand to play—courtesy of his fifteen-million-dollar life insurance policy—especially when her novel based on her girlhood becomes a bestseller. Finally, independent, Samantha moves with her daughter to Hollywood, where she can participate in the production of the movie version of her novel. Dating the film’s producer reveals a dark side to all the Hollywood glam, especially when figures from her Brooklyn past start showing up where they are not wanted. And then there is a mysterious man named John who is showering her with attention. Is this a new romance, or another misstep? And is Sam’s turn in the spotlight worth all the real-life drama?
                            </div>
                            {/* <div className="action-section">
                                <button className='btn more-btn color-white bg-color-white'>
                                    Learn More
                                    <img className='ms-1' src={RedArrow} height={25} width={25} />
                                </button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;