import React, { useEffect } from 'react';
import Mission3 from '../Image/Mission_3.png';
import { useNavigate } from 'react-router-dom';

const TheMission = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('auth') !== '1') {
      navigate("/Home");
    }
  }, []);
  return (
    <div className='the-mission-section'>
      <div className='right-side'>
        <div className='detail-section'>
          <div className='title'>The <span className='red-color'>Mission</span></div>
          <div className='paragraph'>
            <p><span className='headp'>SAMCOR Productions, Inc.</span> will combine its content and expertise to partner with the right companies in flm, television and media streaming. To produce the highest quality of entertainment viewing with purpose. With a solid mission in mind for women's programming, as well as high quality commercial programming.</p>
          </div>
        </div>
      </div>
      <div className='left-side'>
        <img src={Mission3} className='img-fluid' alt='image' />
      </div>
    </div>
  )
}

export default TheMission;