import React, { useEffect } from 'react';
import img from './../Image/BroadWay.png';
import { useNavigate } from 'react-router-dom';

const Broadway2023 = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('auth') !== '1') {
      navigate("/Home");
    }
  }, []);
  return (
    <div className='broadway-section'>
      <div className='left-side'>
        <img src={img} alt='image' />
      </div>
      <div className='right-side'>
        <div className='detail-section'>
          <div className='title'><span className='red-color'>Broadway Play</span></div>
          <div className='title'>Brooklyn Story; Stage Play</div>
          <div className='sub-title'>
            <div>Broadway 2024</div>
            <div> Producers; Tony award winner Daryl Roth and</div>
            <div> Suzanne Corso Stage play by award winning playwright; TBA</div>
          </div>
          <div className='paragraph'>
            <p><span className='headp'>SAMCOR Productions, Inc.</span>Playwright TBA, based on the bestselling novel “Brooklyn Story” Simon & Schuster 2011) Suzanne Corso’s debut novel, Brooklyn Story, chronicles Samantha Bonti’s life as a young woman from Brooklyn struggling to rise above her poverty and get over the bridge. A classic journey of a young woman’s struggle to forge ahead in order to gain her own identity. While faced with challenges of leaving behind her dangerous boyfriend and a bleak Brooklyn life in the 1970’s. Samantha grew up with her doting Jewish grandmother and sickly, neurotic, converted Catholic mother.
              She skillfully walks through the verbal minefield that is their small lower class apartment in Brooklyn. Samantha is a paradox: sweet and intense, innocent and worldly. </p>
          </div>
        </div>
      </div >
    </div >
  )
}

export default Broadway2023;