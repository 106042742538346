import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../Image/Login_Logo.png';
import Facebook from "../Image/Facebook.png";
import Twitter from "../Image/Twitter.png";
import LinkedIn from "../Image/Linkdin.png";
import Instagram from "../Image/Instagram.png";
import { Link } from "react-router-dom";

const Login = () => {

    const navigate = useNavigate();
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");

    const submitClick = () => {
        if (userName === 'samcore' && password === 'samcore') {
            localStorage.setItem('auth','1');
            navigate("/CompanyOverview");
        }
    };

    return (
        <div className="login-section">
            <div className="login-form">
                <div className="left-box">
                    <div className="logo-section">
                        <img src={Logo} alt='logo' />
                    </div>
                    <div className='info-section'>
                        <p>Is a production company with projects in Film, Television & Broadway. Our goal is empowering female driven works, that can impact all.</p>
                    </div>
                    <div className='social-link-section'>
                        <Link to="https://www.facebook.com/suzannecorsolife" target="_blank"><img src={Facebook} height={35} width={35} /></Link>
                        <Link to="https://twitter.com/suzannecorso?lang=en" target="_blank"><img src={Twitter} height={30} width={30} /></Link>
                        <Link to="https://www.instagram.com/suzannecorso/?hl=en" target="_blank"><img src={Instagram} height={35} width={35} /></Link>
                        <Link to="http://www.linkedin.com/in/suzannecorso" target="_blank"><img src={LinkedIn} height={35} width={35} /></Link>
                    </div>
                </div>
                <div className="right-box">
                    <div className="title">Sign In</div>
                    <div className="field-section">
                        <div className="field">
                            <label className="field-label">Email or Username</label>
                            <input type="text" value={userName} onChange={(e) => { setUserName(e.target.value) }} className="form-control" name="email" placeholder="Email or Username" />
                        </div>
                        <div className="field">
                            <label className="field-label">Password</label>
                            <input type="password" value={password} onChange={(e) => { setPassword(e.target.value) }} className="form-control" name="password" placeholder="Password" />
                        </div>
                    </div>
                    {/* <div className="forgot-section">
                        <div className="remember-text">Remember Me</div>
                        <div className="forgot-password-text">Forgot Password?</div>
                    </div> */}
                    <div className="btn-section">
                        <button type="button" className="btn btn-primary btn-sign" name="sign_button" onClick={submitClick} >Sign In</button>
                    </div>
                    {/* <div className="sign-up-section">
                        <div className="note-a-text">Not a member?</div>
                        <div className="sign-up-text">Sign Up Now</div>
                    </div> */}
                    <div className='copyrights-text-mobile'>© 2023 Suzanne Corso | All Rights Reserved</div>
                </div>
            </div>
            <div className='copyrights-text'>© 2023 Suzanne Corso | All Rights Reserved</div>
        </div>
    )
};
export default Login;