import Facebook from "../Image/Facebook.png";
import Twitter from "../Image/Twitter.png";
import LinkedIn from "../Image/Linkdin.png";
import Instagram from "../Image/Instagram.png";
import { Link } from "react-router-dom";

const Footer = () => {

    const gotoSection = (section: string) => {
        const element = document.getElementById(section);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className='footer-section'>
            <div className="social-icon-container">
                <div className="line"></div>
                <div className='icon-section'>
                    <Link to="https://www.facebook.com/suzannecorsolife" target="_blank"><img src={Facebook} height={35} width={35} /></Link>
                    <Link to="https://twitter.com/suzannecorso?lang=en" target="_blank"><img src={Twitter} height={30} width={30} /></Link>
                    <Link to="https://www.instagram.com/suzannecorso/?hl=en" target="_blank"><img src={Instagram} height={35} width={35} /></Link>
                    <Link to="http://www.linkedin.com/in/suzannecorso" target="_blank"><img src={LinkedIn} height={35} width={35} /></Link>
                </div>
                <div className="line"></div>
            </div>
            <nav className="nav">
                <a onClick={() => { gotoSection('home') }}>Home</a>
                <a onClick={() => { gotoSection('about') }}>About</a>
                <a onClick={() => { gotoSection('book') }}>My Books</a>
                {/* <a>Samcore Production</a>
                <a>Contact Us</a> */}
            </nav>
            <div className="copyright-section">
                © 2023 Suzanne Corso | All Rights Reserved
            </div>
        </div>
    )
}

export default Footer