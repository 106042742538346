import React, { useEffect } from 'react'
import Suzane_Corso from './../Image/suzane_corso1.png';
import { useNavigate } from 'react-router-dom';

export default function SuzanneCorso() {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('auth') !== '1') {
      navigate("/Home");
    }
  }, []);
  return (
    <>
      <div className='suzane-corso'>
        <div className='left-side'>
          <img src={Suzane_Corso} alt='logo' className='img-fulid' />
        </div>
        <div className='right-side'>
          <div className='detail-section'>
            <div className='title'>Suzanne <span className='red-color'>Corso</span></div>
            <div className='sub-title'>Founder & CEO</div>
            <div className='sub-title-light'>
              <div>Author/Screenwriter/Producer</div>
              <div>www.samcorproductions.com</div>
            </div>
            <div className='paragraph'>
              <p>Corso is the author of three novels. Brooklyn Story (2011), The Suite Life (2013) & Hello Hollywood (2015), the last part of the trilogy; (Simon& Schuster).
                She is also completing her highly waited trilogy; Rough Cuts, set in exotic locals encompassing the very alluring diamond industry; to be published 2022. She has also penned the screenplay to Brooklyn Story. Corso has produced for the New York and London stages. She has also produced two documentaries and has written a children's book. Corso is currently in development of The Suite Life & Hello Hollywood to TV Series. She was a contributing TV Film Correspondent to NBC Today Show and columnist for Downtown Magazine. Currently Brooklyn Story is being negotiated into a feature flm.</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className='suzane-corso-section'>
          <div className='label'>
            Access
          </div>
          <div className='title'>
            Hollywood
          </div>
          <div className='paragraph'>
            <p>www.accesshollywood.com/the-hottest-male-superstars-of- tennis-video- 2331837</p>
            <p>www.accesshollywood.com/ffty-shades-of-grey-trailer-hot-or-not-video-2268837</p>
          </div>
        </div>
        <div className='suzane-corso-section'>
          <div className='label'>
            TV links
          </div>
          <div className='title'>
            Today Show- Film Correspondent pieces
          </div>
          <div className='paragraph'>
            <p>m.today.com/popculture/spend-your-v-day-these-all- time-great-romantic-movies-2D80493734</p>
            <p>www.today.com/popculture/todays-guide-what-movie- you-should-see-weekend-2D80210882</p>
            <p>www.today.com/video/today/55681675 http://www.today.com/video/today/55300146#55300146</p>
            <p>www.today.com/entertainment/x-men-or-blended- today-picks-your-memorial-day-weekend-2D79712546</p>
          </div>
        </div>
        <div className='suzane-corso-section'>
          <div className='label'>
            Book Videos & Interviews
          </div>
          <div className='title'>
            Interview; Maria Bartiromo
          </div>
          <div className='paragraph'>
            <p>video.foxbusiness.com/v/4282809371001/i-lost-100m- and-i-like-it/?playlist-id=3166411554001#sp=show-clips</p>
            <p className='interview'>Interview; Rosanna Scotto, Good Day New York</p>
            <p>www.myfoxny.com/clip/11507213/hello-hollywood</p>
          </div>
        </div>
        <div className='suzane-corso-section'>
          <div className='title'>
            Videos
          </div>
          <div className='paragraph'>
            <p>vimeo.com/20398486</p>
            <p className='interview'>Olympia Dukakis</p>
            <p>vimeo.com/18905420?ref=em-share</p>
            <p className='interview'>Lorraine Bracco</p>
          </div>
        </div>
        <div className='suzane-corso-section'>
          <div className='title'>
            Ny Post
          </div>
          <div className='paragraph'>
            <p>nypost.com/2015/06/05/i-lost-100-million-and-ive- never-been-happier/</p>
          </div>
        </div>
      </div>
    </>
  )
}
