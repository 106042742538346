import React, { useEffect } from 'react';
import Book1 from "../Image/book 1.png"
import Book2 from "../Image/book 2.png"
import Book3 from "../Image/book 3.png"
import { useNavigate } from 'react-router-dom';

const Books = () => {

    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('auth') !== '1') {
            navigate("/Home");
        }
    }, []);

    return (
        <div className='books-section'>
            <div className='books-title'>
                Books
            </div>
            <div className='books-label'>
                From The NY Times Best-selling author of the critically acclaimed;
            </div>
            <div className='book-image-section'>
                <img src={Book1} className='img-fluid' />
                <img src={Book2} className='img-fluid' />
                <img src={Book3} className='img-fluid' />
            </div>
            <div className='red-section'>
                <div className='main-section'>
                    <div className='position-relative'>
                        <div style={{ position: "absolute", top: "-85px", right: "90px" }}>
                            <img src={Book1} height={250} width={175} />
                        </div>
                    </div>
                    <div className='position-relative'>
                        <div style={{ position: "absolute", top: "-85px", left: "-85px" }}>
                            <img src={Book2} height={250} width={175} />
                        </div>
                    </div>
                    <div className='position-relative'>
                        <div style={{ position: "absolute", top: "-85px", left: "95px" }}>
                            <img src={Book3} height={250} width={175} />
                        </div>
                    </div>
                </div>
                <div className='title'>“Brooklyn Story”, “The Suite Life” & “Hello Hollywood”.</div>
                <div className='label'>published by (Simon & Schuster)</div>
                <div className='paragraph main-container'>
                    From the author of the best selling and critically acclaimed and one of USA TODAY's celebrated "New Voices of 2011,"comes two new novels which expose the over-the-top decadence-and corruption-of Wall Street's elite and Hollywood's financiers.
                </div>
            </div>
        </div>
    )
}

export default Books