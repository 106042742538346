import React, { useEffect } from 'react';
import Novel from "../Image/Novel.png"
import Novel_1 from "../Image/Novel_1.png"
import Novel_2 from "../Image/Novel_2.png"
import Novel_3 from "../Image/Novel_3.png"
import { useNavigate } from 'react-router-dom';

const NovelsScripts = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('auth') !== '1') {
      navigate("/Home");
    }
  }, []);
  return (
    <div className='novel-section'>
      <div className='main-container'>
        <div className='web-block'>
          <div className='row mb-5'>
            <div className='col-md-5'>
              <div className="image-section">
                <div className='img-title'>
                  <div className='title'>Projects/Based on Books</div>
                  <div className='novel-title'>Film Platform</div>
                </div>
              </div>
            </div>
            <div className='col-md-7'>
              <img src={Novel} width={859} />
            </div>
          </div>
        </div>

        <div className='novel-title'>
          Novels & <span>Scripts</span>
        </div>
        <div className='sub-title'>
          Script <span>Completed</span>
        </div>
        <div className='novel-image-section'>
          <img src={Novel_1} />
          <div className='novel-image-paragraph'>
            {/* <div>SAMCOR PRODUCTIONS by Suzanne Corso,</div>
            <div> WRITTEN BY Suzanne Corso, based on the novel “RoughCuts”.</div>
            <div> TRILOGY TO BE PUBLISHED 2024</div> */}
            <div>
              Samcor Productions created & written by The first book<br />
              Rough Cuts “Blue Diamond” by Suzanne Corso<br />
              Screenplay By Suzanne Corso, based on the novel "Rough Cuts".<br />
              Trilogy to be published in 2024
            </div>
          </div>
        </div>
        <div className='last-paragraph-section'>
          <div><span>“ROUGH CUT”</span> A woman, treated by her family of diamond dealers as a ‘weak member of the opposite sex’, surprises them all, right under their noses, by becoming a jewel thief. She and her two young and sexy accomplices plot and the flawlessly execute the of precious colored ‘rough cuts’. To spite the men, despite the dangerous betrayals and the innate jealousy, they prevail and end up independent, confident, and rich beyoud their wildest dreams. JEWEL SILVANE was given her first name by her parents, VERA and her father STAN. Vera is Jewish and Stan is a rarity … an Italian jeweler in the Diamond District. Jewel decides, early on, to defy her overbearing parents and pursue a career as a gemologist. She wanted to be rich, and she wanted to make it on her own. She dreamed about surrounding herself with diamonds of all shapes and sizes, and once and for all, to prove herself in the jewelry business to her father, the bastard who never gave her or any woman a chance.</div>
        </div>
      </div>
      <div className='devlopment-section'>
        <div className='main-container'>
          <div className='devlopment-title'>
            In <span>Development</span>
          </div>
          <div className='box-section'>
            <div className='devlopment-image-section'>
              <div>
                <div className='img-section'>
                  <img src={Novel_2} />
                  <div className='devlopment-image-paragraph'>
                    {/* <div>SAMCOR PRODUCTIONS</div>
                    <div> WRITTEN by TBA </div>
                    <div> WRITTEN by Suzanne Corso & TBA,</div>
                    <div>based on the novel “RoughCuts, The</div>
                    <div> Infamous Red”.</div> */}
                    <div>
                      Rough Cuts,”The Infamous Red” by Suzanne Corso Screenplay by Suzanne Corso & TBA
                    </div>
                  </div>
                </div>
                <div className='last-paragraph-section'>
                  <div><span>“ROUGH CUT”</span> A woman, treated by her family of diamond dealers as a ‘weak member of the opposite sex’, surprises them all, right under their noses, by becoming a jewel thief. She and her two young and sexy accomplices plot and the flawlessly execute the of precious colored ‘rough cuts’. To spite the men, despite the dangerous betrayals and the innate jealousy, they prevail and end up independent, confident, and rich beyoud their wildest dreams. JEWEL SILVANE was given her first name by her parents, VERA and her father STAN. Vera is Jewish and Stan is a rarity … an Italian jeweler in the Diamond District. Jewel decides, early on, to defy her overbearing parents and pursue a career as a gemologist. She wanted to be rich, and she wanted to make it on her own. She dreamed about surrounding herself with diamonds of all shapes and sizes, and once and for all, to prove herself in the jewelry business to her father, the bastard who never gave her or any woman a chance.</div>
                </div>
              </div>
            </div>
            <div className='devlopment-image-section'>
              <div className='margin-left'>
                <div className='img-section'>
                  <img src={Novel_3} />
                  <div className='devlopment-image-paragraph'>
                    {/* <div>SAMCOR PRODUCTIONS</div>
                    <div> WRITTEN by TBA </div>
                    <div> WRITTEN by Suzanne Corso & TBA,</div>
                    <div>based on the novel “RoughCuts, The</div>
                    <div>The Yellow Sun”.“ROUGH CUTS, The Yellow Sun”</div> */}
                    <div>
                    Rough Cuts,”The Yellow Sun” by Suzanne Corso Screenplay by Suzanne Corso & TBA
                    </div>
                  </div>
                </div>
                <div className='last-paragraph-section'>
                  <div><span>“ROUGH CUT”</span> A woman, treated by her family of diamond dealers as a ‘weak member of the opposite sex’, surprises them all, right under their noses, by becoming a jewel thief. She and her two young and sexy accomplices plot and the flawlessly execute the of precious colored ‘rough cuts’. To spite the men, despite the dangerous betrayals and the innate jealousy, they prevail and end up independent, confident, and rich beyoud their wildest dreams. JEWEL SILVANE was given her first name by her parents, VERA and her father STAN. Vera is Jewish and Stan is a rarity … an Italian jeweler in the Diamond District. Jewel decides, early on, to defy her overbearing parents and pursue a career as a gemologist. She wanted to be rich, and she wanted to make it on her own. She dreamed about surrounding herself with diamonds of all shapes and sizes, and once and for all, to prove herself in the jewelry business to her father, the bastard who never gave her or any woman a chance.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NovelsScripts;
