import React, { useEffect } from 'react';
import Book4 from "../Image/Book_4.png"
import Book5 from "../Image/Books_4.png"
import Book6 from "../Image/Books_5.png"
import Book7 from "../Image/Books_6.png"
import { useNavigate } from 'react-router-dom';

export default function InDevelopment() {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('auth') !== '1') {
      navigate("/Home");
    }
  }, []);
  return (
    <div className='in-development-section'>
      <div className='title'>
        Projects/Based on Books
      </div>
      <div className='streaming-title'>
        Television Platform
      </div>
      <div className='devlopment-section'>
        <div className='devlopment-title'>
          In <span>Development</span>
        </div>
        <div className='main-container'>
          <div className='devlopment-paragraph'>
            <div>SAMCOR PRODUCTIONS CREATED & WRITTEN</div>
            <div> by Suzanne Corso</div>
            <div> “GRAYROCKING”- Television series and/or limited series</div>
            <div>The difference between Black - White = GRAY</div>
          </div>
          <div className='overview-section'>
            <div className='write-section'>
              <div>John is dysfunctional, John is captivating, John is mysterious, John is narcissism, John is sociopath, and John is FREED!</div>
              <div>Welcome to the dark life of a narcissist, who is a ten-year felon, leaves jail to start a new with an empathetic x-girlfriend, with whom he loved for over 30 years and is</div>
              <div> now a famous author.</div>
              <div> Story told from the POV of a female. Wild and twisted journey.</div>
            </div>
          </div>
          <div className='devlopment-paragraph'>
            <div>SAMCOR PRODUCTIONS CREATED & WRITTEN</div>
            <div> by Suzanne Corso</div>
            <div> “MARY”– Limited Mini Series</div>
          </div>
          <div className='overview-section'>
            <div className='write-section'>
              The story of the most amazing woman that ever existed. From her birth, as a poor Jewish girl to the birth of her famous son to her mysterious death known to millions as the assumption. Story opens with Blessed Mother being born and ends when she gives birth to Jesus.
              DREAM LIST Angelina Jolie as Mary; Executive producers Oprah Winfrey - HBO 3 to 6-part miniseries.
            </div>
          </div>
        </div>
      </div>
      <div className='last-section'>
        <div className='main-container'>
          <div className='book-image-section'>
            <img src={Book4} />
            <div className='book-image-paragraph'>
              <div>SAMCOR PRODUCTIONS CREATED & WRITTEN</div>
              <div> by Suzanne Corso</div>
              {/* <div> “GRAYROCKING”- Television series and/or limited series</div> */}
              <div>“Sammy&Sue”</div>
              {/* <div>The difference between Black - White = GRAY</div> */}
            </div>
          </div>
        </div>
        <div className='main-container'>
          <div className='last-paragraph-section'>
            <div>Sammy & Sue is a mother-daughter explorer team. You name it, they can discover it. Their one mission is to save the planet, educate the children and help the animals on their journeys.</div>
            <div>Through real live footage of the animals, animation and celebrity guests, Sammy & Sue’s adventures and environmental, eco-friendly excursions will capture all who watch. Samantha Corso (Sammy) & Suzanne Corso (Sue), Samantha is a creator and explorer by birth. Suzanne is Samantha’s mom. She is a writer and explorer by nature.</div>
          </div>
          <div className='last-paragraph-image-section'>
            <div className='last-img-section'>
              Suzanne embarked on the children’s book series, Sammy & Sue so she and her curious little girl can travel the world understanding the environment more, the animals more and helping to solve problems that our planet faces today, all while marking each journey fun filled, action packed and educational.
              Sammy dreams of growing up and becoming the teacher that the next generation needs. Together Sammy & Sue will forge ahead and help to make your children enjoy each adventure with a smile. Each episode is based upon the Sammy & Sue books. Sammy & Sue will educate, solve problems and come up with solutions to help our environment, animals and any situation which they are presented with.
              <div className='img-section'>
                <img src={Book6} />
                <img src={Book5} />
                <img src={Book7} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
