import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const InvestmentAmendment = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('auth') !== '1') {
      navigate("/Home");
    }
  }, []);
  return (
    <div className='samcore-investment-section'>
      <div className='title'>
        Samcor Investment
      </div>
      <div className='streaming-title'>
        Deck Amendment
      </div>
      <div className='mt-3 mb-3'>
        <div className='samcore-investment-title'>In Depth Use the Proceeds:</div>
        <div className='samcore-investment-label'>Development of Projects. Writer & Producer fees</div>
      </div>
      <div className='samcore-investment-paragraph'>
        <span>BROOKLYN STORY</span> Musical for Broadway. Daryl Roth Producer. Ten-time Tony Winner. Hire Award winning Playwright. Will do a three-act play. After its competition, we mount in three different cities, then it comes to Broadway. Daryl will bring investors. <span>Writer $100K Workshop $100K</span>
      </div>
      <div className='samcore-investment-paragraph'>
        <span>THE SUITE LIFE & HELLO HOLLYWOOD</span>scripts to be written along with Suzanne Corso.<span>Based on books - $100K</span>
      </div>
      <div className='samcore-investment-paragraph'>
        <span>ROUGH CUTS</span> Books & Scripts. Hire A list writer for edits, books two and three. Begin preliminary work on Scripts, TV treatment and Bible for all three. Four separate projects.<span>Writers $100K</span>
      </div>
      <div className='samcore-investment-paragraph'>
        <span>GRAYROCKING.</span>Hire one to two writers to develop show and create a full television bible and write the Pilot episode. Limited series for Netflix, Hulu, Amazon. Possibly three seasons at ten episodes apiece. Treatment and Bible and pilot script.<span> Writers $100K</span>
      </div>
      <div className='samcore-investment-paragraph'>
        <span>MARY. </span>Hire writers for treatment and limited series scripts. Research in Jerusalem and Nazareth. Will be as accurate as we as we can with this story. Mary Limited series three to six episodes. Television treatment, as well as pilot and first script.<span>Writer $100K</span>
      </div>
      <div className='samcore-investment-paragraph'>
        <span>SAMMY & SUE</span>Books and TV. Hire a children’s writer to develop TV series. Corso will continue writing books along with another writer. Sammy & Sue, ten books; completed. Flushing out television treatment and Bible. Getting the pilot script prepared.<span>Writer $200k</span><br />
        <span>• Illustrator- possible $50K</span>
      </div>
      <div className='samcore-investment-paragraph'>
        <span>SIX REALITY SHOWS</span>(all inside Samcor Deck) Attaching writers and producers. To sell immediately. Reality shows to be more developed and flushed out treatments and outlines.<span>Writers $200k</span>
      </div>
      <div className='samcore-investment-label mt-4 mb-3'>
        Primary Cash Expenditures:
        <div className='ms-4 label'>
          <ul>
            <li>Travel Meeting</li>
            <li>Developing Website; Samcor Productions. www.samcorproductions.com</li>
            <li>Samcor Stationary & Business cards. Office rent, utilities, assistant and misc. </li>
            <li>Purchase 50 books of each title for producers and other PR outlets as business grows. $300K</li>
          </ul>
        </div>
      </div>
      <div className='samcore-investment-label mt-4 mb-3'>
        Total Use of Proceeds $1.5M
        <div className='label'>
        $250K in reserve, only to be touched if need be.
        </div>
      </div>
      <div className='samcore-investment-label mt-4 mb-3'>
        Samcor Investment Percentage Payback
        <div className='label'>
          <div className='price-tag'>
            $1,500,000
          </div>
          Equity: $1.5 Million for 15% equity and Samcor Productions.
        </div>
      </div>
    </div>
  )
}

export default InvestmentAmendment