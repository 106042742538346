import React, { useEffect } from 'react';
import img from './../Image/Multiple.png';
import { useNavigate } from 'react-router-dom';


export default function MultipleRevenueStreams() {

  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('auth') !== '1') {
      navigate("/Home");
    }
  }, []);
  
  return (
    <div className='multiple-revenue-streams'>
      <div className='left-side'>
        <img src={img} alt='image' />
      </div>
      <div className='right-side'>
        <div className='detail-section'>
          <div className='title'><span className='red-color'>Multiple</span></div>
          <div className='title'>Revenue Streams</div>
          <div className='paragraph'>
            <p><span className='headp'>SAMCOR Productions, Inc.</span> revenue will be derived from movies produced and distributed, television projects and media streaming projects, made and aired domestically and internationally. Overtime, the company will be seeking to expand its operations utilizing strategic partner relationships and by the prudent hiring of additional management and producers. The company will focus on controlling costs in Order to minimize the risk and maximize profits. In this regard, it will be SAMCOR’s policy for its principal and any other management to draw only a minimal salary until the company is profitable</p>
          </div>
        </div>
      </div>
    </div>
  )
}
